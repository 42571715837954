/**Growl**/

.p-growl {
  position: fixed;
  width: 20em;
}

.p-growl.p-growl-topright {
  top: 20px;
  bottom: auto;
  right: 20px;
  background-color: #f56565;
}

.p-growl.p-growl-topleft {
  top: 20px;
  bottom: auto;
  left: 20px;
}

.p-growl.p-growl-bottomleft {
  top: auto;
  bottom: 20px;
  left: 20px;
}

.p-growl.p-growl-bottomright {
  top: auto;
  bottom: 20px;
  right: 20px;
}

.p-growl-item-container {
  position: relative;
  margin: 0 0 10px 0;
}

.p-growl-item {
  position: relative;
  padding: 0.5em 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.p-growl-item p {
  padding: 0;
  margin: 0;
}

.p-growl-icon-close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  background-color: white;
  color: white;
}

.p-growl-title {
  font-weight: bold;
  display: block;
}

.p-growl-details {
  margin-top: 0.5em;
}

.p-growl-image {
  font-size: 2em;
}

.p-growl-message {
  margin-left: 1em;
  align-self: center;
}

.p-growl-message p {
  font-weight: normal;
}

/* Animations */

.p-growl-enter {
  opacity: 0.01;
}

.p-growl-enter.p-growl-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.p-growl-exit {
  max-height: 500px;
}

.p-growl-exit.p-growl-exit-active {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1),
    margin-bottom 0.5s cubic-bezier(0, 1, 0, 1);
}
