/**Password**/

.p-password-panel {
  padding: 0;
  margin-top: 0;
}

.p-password-panel .p-password-meter {
  height: 0;
  background: none;
  display: none;
  padding: 0;
  margin: 0;
}

.p-password-info {
  margin-top: 0;
  display: none;
}

.p-password-panel-overlay {
  position: unset;
}

.p-password-panel
  .p-component
  .p-highlight
  .p-hidden
  .p-password-panel-overlay
  .p-input-overlay
  .p-input-overlay-hidden {
  display: none !important;
}

/**Button**/

.p-button {
  margin-right: 0px;
}

/** Smooth scroll**/
html {
  scroll-behavior: smooth;
}

/**Tabs border bottom**/
.borderClass:after {
  content: '';
  display: block;
  margin: 0;
  width: 85%;
  padding-top: 2px;
  border-bottom: 3px solid #20b716;
}

/**ul list disc style**/
ul.customDisc {
  list-style: none; /* Remove default bullets */
}

ul.customDisc li::before {
  content: '\2022';
  color: #20b716;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/**  cut out **/

.cut-out {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  z-index: 1;
}

.cut-out:before {
  content: '';
  position: absolute;
}

.cut-out:after {
  content: '';
  position: absolute;
  /* right:0; */
}

.cut-out:before {
  top: 32%;
  left: -25px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 40rem #fff;
  z-index: -1;
}
.cut-out:after {
  top: 32%;
  right: -25px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 40rem #fff;
  z-index: -1;
}

/** FOR SEARCHBOX **/
.marginPadding {
  margin-left: -1.8rem !important;
  padding-left: 2.2rem !important;
}

/** FOR DROPDOWNSEARCH **/

.customImageAutoCompletePadding {
  padding-left: 2.2rem !important;
}

.p-autocomplete {
  width: 100%;
  zoom: 0;
  cursor: pointer;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  display: inline-block;
}

.p-autocomplete .p-autocomplete-dropdown {
  height: 100%;
  width: 2em;
  margin-right: 0;
  vertical-align: top;
}

.p-autocomplete .p-autocomplete-input {
  padding-right: 1.5em;
}

.p-autocomplete-loader {
  position: absolute;
  right: 0.25em;
  top: 50%;
  margin-top: -0.5em;
}

.p-autocomplete-query {
  font-weight: bold;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
  border: 2px;
  border-color: '#C4C9CC';
  font-size: 1rem;
  background-color: #fff;
  margin-top: 0.625rem;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;
  display: none;
}

.p-autocomplete-panel .p-autocomplete-list {
  padding: 0rem;
  margin: 0;
  border: 2px;
  border-top: 0px;
  border-style: solid;
  border-radius: 0.375rem;
  max-height: 8rem;
  overflow: auto;
}

.p-autocomplete-panel .p-autocomplete-list-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

.p-autocomplete .p-button-icon-only,
.p-autocomplete .p-button-icon-only:enabled:hover,
.p-autocomplete .p-button-icon-only:enabled:focus,
.p-autocomplete .p-button-icon-only:enabled:active {
  border-left: 0 none;
}

/* Multiple Selection */
.p-autocomplete-multiple-container {
  display: inline-block;
  vertical-align: middle;
}

.p-autocomplete-multiple-container.p-inputtext {
  clear: left;
  cursor: text;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0 1.5em 0 0.25em;
}

.p-autocomplete-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}

.p-autocomplete-token-label {
  display: block;
  margin-right: 2em;
}

.p-autocomplete-token-icon {
  margin-top: -0.5em;
  position: absolute;
  right: 0.2em;
  top: 50%;
  cursor: pointer;
}

.p-autocomplete-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;
}

.p-autocomplete-input-token input {
  border: 0 none;
  width: 10em;
  outline: medium none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-loader {
  right: 1.25em;
}

.p-autocomplete-dd input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  -moz-border-radius-topright: 0.375rem;
  -webkit-border-top-right-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  -moz-border-radius-bottomright: 0.375rem;
  -webkit-border-bottom-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/** AutoComplete **/
.p-fluid .p-autocomplete,
.p-fluid .p-autocomplete-input {
  width: 100%;
}

.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-input,
.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-multiple-container {
  width: calc(100% - 2em);
}

.p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
  width: 2em;
}

.wid-full .p-autocomplete.p-component {
  width: 100%;
}

/** Eventdetails social icons**/
.floaterIcons {
  right: 50px;
}

@media screen and (max-width: 1190px) {
  /** Eventdetails social icons**/
  .floaterIcons {
    right: 0px;
  }
}

/** SLIDE MENU **/
.p-slidemenu {
  width: 100%;
  padding: 0.25em;
  min-height: 500px;
  overflow: auto;
}

.p-slidemenu .p-slidemenu-wrapper {
  position: relative;
  height: 100%;
}

/** Calendar **/
.p-calendar .p-datepicker {
  min-width: 100%;
  background-color: white;
  border: 1px solid #ccc;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #cbd5e0;
  /* padding: 20px; */
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.p-datepicker .p-timepicker > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.5em;
  min-width: 1.5em;
}
.p-datepicker td > span,
.p-datepicker td > a {
  display: block;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
}
.p-datepicker table td.p-datepicker-today > span {
  background-color: #d0d0d0;
  color: #333333;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background-color: #ed8936;
  outline: none;
}
.p-disabled {
  cursor: default !important;
  color: #cbd5e0;
}
/* .p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background-color: #007ad9;
} */
.p-calendar .p-inputtext {
  border: 1px solid #a0aec0;
  width: 100%;
  color: #718096;
  background-color: transparent;
}
.p-calendar .p-inputtext::placeholder {
  color: #718096;
}
/** Calendar **/

/** Dialog **/
.p-component-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.p-dialog {
  border: 1px solid transparent;
}
/** Dialog **/
/** DATATABLE - START **/
.p-datatable {
  position: relative;
}
.p-datatable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable .p-datatable-thead {
  border: 1px solid #ccc;
}
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tfoot > tr > td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
  font-weight: lighter;
  padding-right: 0.25rem;
}
.p-datatable .p-datatable-tbody > tr {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.p-datatable .p-datatable-tbody > tr > td {
  color: #353535;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
}

.p-datatable .p-sortable-column-icon {
  vertical-align: middle;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

/* Sections */
.p-datatable-header,
.p-datatable-footer {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold;
}

.p-datatable-header {
  border-bottom: 0 none;
}

.p-datatable-footer {
  border-top: 0 none;
}

/* Paginator */
.p-datatable .p-paginator-top {
  border-bottom: 0 none;
}

.p-datatable .p-paginator-bottom {
  border-top: 0 none;
}

/* Scrollable */
.p-datatable-scrollable-wrapper {
  position: relative;
}
.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.p-datatable-scrollable-body {
  overflow: auto;
  position: relative;
}

.p-datatable-scrollable-body
  > table
  > .p-datatable-tbody
  > tr:first-child
  > td {
  border-top: 0 none;
}

.p-datatable-virtual-table {
  position: absolute;
}

/* Frozen Columns */
.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-frozen-view
  > .p-datatable-scrollable-body
  > table
  > .p-datatable-tbody
  > tr
  > td:last-child {
  border-right: 0 none;
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Filter */
.p-column-filter {
  width: 100%;
}

/* Resizable */
.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: hidden;
}

.p-datatable-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

/* Edit */
.p-datatable .p-datatable-tbody > tr > td.p-cell-editing .p-component {
  width: 100%;
}

/* Reorder */
.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Toggleable RowGroup */
.p-datatable .p-rowgroup-header .p-row-toggler .p-row-toggler-icon {
  vertical-align: text-bottom;
}

/* Responsive */
.p-datatable-responsive
  .p-datatable-tbody
  > tr.p-datatable-row
  > td
  .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  .p-datatable-responsive .p-datatable-thead > tr > th,
  .p-datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    float: left;
    clear: left;
  }

  .p-datatable-responsive
    .p-datatable-tbody
    > tr.p-datatable-row
    > td
    .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
}

/* Loader */
.p-datatable-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-datatable-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2em;
}
/** DATATABLE - END **/

/** Wobble Animation **/

.wobble-hor-top {
  -webkit-animation: wobble-hor-top 0.8s both;
  animation: wobble-hor-top 0.8s both;
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-8 15:29:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}
@keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-8 15:36:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/** Wobble Animation **/

/* ----------------------------------------------
 * Generated by Animista on 2020-5-11 19:32:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
